import { Container, Grid } from '@mantine/core'
import { } from 'antd-mobile'
import './blackbelt.css'
import powder from '../../resources/powder.png'
import { useResizeObserver } from '@mantine/hooks';

export function Blackbelt() {

    const [ref, rect] = useResizeObserver();

    return (
        <>
            <div className='blackBeltSpacer' />
            <div className='blackBelt' ref={ref}>
                <Container size="xl" my="xs">
                    <Grid grow>
                    {rect.width > 1000 &&
                        <Grid.Col xs={6}>
                            <img id="blackBeltImage" src={powder}/>        
                        </Grid.Col>}

                        <Grid.Col xs={6}>
                            <h1 className='blackBeltText blackBeltHeadline'>BattMatt</h1>
                            <p className='blackBeltText'> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam volu</p>
                        </Grid.Col>

                    </Grid>
                </Container>
            </div>
            <div className='blackBeltSpacer' />
        </>
    )
}