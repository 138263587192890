import { Container, Grid } from '@mantine/core'
//import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
//import { useEffect } from 'react';
import './pricing.css'

export function Pricing() {

    /*const storage = getStorage();
    const storageRef = ref(storage, "images/camilo-contreras-PqA6OAHeVG0-unsplash.jpg");
    const url = getDownloadURL(storageRef)
    console.log(url)*/

    return (
        <Container size="xl" style={{ marginTop: "10vh" }} my="xs">
            <Grid grow>
                <Grid.Col xl={12}>
                    <img style={{ borderRadius: "20px", width: "100%" }} src="https://images.unsplash.com/photo-1579227114347-15d08fc37cae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" />
                </Grid.Col>
            </Grid>
        </Container>
    )
}