import { Container, Grid, Image, Paper, Table, TypographyStylesProvider } from '@mantine/core'
import { ImageUpload } from '../imageUpload'
import { getAuth } from "firebase/auth";
import { TextEditor } from '../textEditor'
import { onSnapshot, collection, DocumentData } from "firebase/firestore";
import { useEffect, useState } from 'react';
import db from '../../firebase'

import './articles.css'

interface Article {
    title?: string;
    shortTitle: string;
    description?: string;
    text: string;
}

export function Articles() {

    const [articles, setArticles] = useState<DocumentData[]>([]);
    useEffect(() =>
        onSnapshot(collection(db, "Articles"), (snapshot) => {
            setArticles(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }),
        []
    );

    const auth = getAuth();
    const currentUser = auth.currentUser;

    return (
        <Container
            //size="xl" 
            id='article-container' my="xs">
            <Grid grow>
                <Grid.Col>
                    <h1>Articles</h1>
                    {articles.map((item, index) =>
                        <Paper key={index} id={item.shortTitle} className='Article-background' shadow="xl" p="md">
                            {item.image&&
                            <Image width={"100%"} height={300} src={item.image} alt="Panda" />
                            }

                            <TypographyStylesProvider>
                                <div dangerouslySetInnerHTML={{ __html: item.text }} />
                            </TypographyStylesProvider>

                        </Paper>
                    )}
                </Grid.Col>
                {currentUser &&
                    <Grid.Col>
                        <TextEditor />
                    </Grid.Col>}
            </Grid>
        </Container>
    )
}