// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyC2mBkHkZaCgn8l5NYHfIxKdVvqrSsfbhs",
  authDomain: "battmatt-1b598.firebaseapp.com",
  projectId: "battmatt-1b598",
  storageBucket: "battmatt-1b598.appspot.com",
  messagingSenderId: "651130104338",
  appId: "1:651130104338:web:2faf4b08153e6ccd05c6cb"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const storage = getStorage(app);

export default getFirestore();

export { storage, app };